<template>
    <b-card title="Suggestion de modification">
      
      <b>Nom :</b><br />
      {{ edit_suggest.name }}

      <br /><br />
      <b>Prix :</b><br />
      {{ edit_suggest.price }}

      <br /><br />
      <b>Nom de la marque :</b><br />
      {{ edit_suggest.brand_name }}

      <br /><br />
     
      <b>Description :</b><br />
      {{ edit_suggest.description }}

      <br /><br />
      <b-button v-b-modal.modal-danger  variant="danger">Supprimer</b-button>

      <b-modal
      id="modal-danger"
      cancel-variant="outline-secondary"
      cancel-title="Non"
      ok-variant="danger"
      ok-title="Oui"
      @ok="deletePromotion()"
      modal-class="modal-danger"
      centered
      title="Êtes-vous sûr ?"
    >
      <b-card-text>
        Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer cette suggestion ?
      </b-card-text>
    </b-modal>

    </b-card>
    
</template>

<script>
import {BModal, BButton, VBModal, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton
  },
  data() {
    return {
      edit_suggest:null,
      loaded:false,
      selectedItem:null
    }
  },
  methods:{
    async deletePromotion()
    {
      this.loading=true
      try {
        await APIRequest.delete('edit-suggests',this.edit_suggest.id)
        
        this.loading=false
        this.$router.push({name:'edit-suggests'})
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'La suggestion a été supprimée !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
  },
  async mounted()
  {
    this.edit_suggest=await APIRequest.detail('edit-suggests',this.$route.params.id)
    this.loaded=true
  }
}
</script>
